export default {
  addSupplier: 'Add supplier',
  updateCurrentSupplier: 'Update Current supplier',
  supplier: 'Supplier',
  saler: 'Seller',
  boughtAdmin: 'Bought Admin',
  confirmedWarehouser: 'Confirmed Warehouser',
  //suppliers fields
  fullName: 'Full name',
  phone: 'Phone',
  phone1: 'Phone 1',
  phone2: 'Phone 2',
  email: 'Email',
  location: 'Location',
  landmark: 'Landmark',
  responsiblePerson: 'Responsible person',
  telegram: 'Telegram',
  productTopics: 'Product topics',
  probability: 'Probability',
  conclusion: 'Conclusion',
  advertisements: 'Advertisements',
  supplierType: 'Supplier type',
  companyPosition: 'Company position',
  organization: 'Organization',

  //supply report
  totalPrice: 'Total price',
  totalCount: 'Total count',
  ourDebtMoney: 'Our debt money',
  ourPaidMoney: 'Our paid money',
  productCount: 'Product count',

  supplyBy: 'Supply by',

  boughtDate: 'Bougth date',
  goToDoc: 'Go to document',

  //supply history
  supplyHistoryTitle: 'Supply history',
  adminConfirmation: 'Admin confirmation',
  warehouserConfirmation: 'Warehouser confirmation',
  document: 'Document',
  currency: 'Currency',
  originalPrice: 'Original price',
  discount: 'Discount',
  paidMoney: 'Paid money',
  debtMoney: 'Debt money',
  documentSaved: 'Document saved',

  warehouseConfirmed: 'Warehouse confirmed',
  adminConfirmed: 'Admin confirmed',
  notConfirmed: 'Not confirmed',

  // buy product
  buyProductTitle: 'Buy product from supplier',
  dateToPay: 'Date to pay',
  products: 'Products',
  addProducts: 'Add products',
  paid: 'Paid',
  debt: 'Debt',

  productNotAdded: 'Product not added',
}