import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

// Creating Context
const ImageViewerContext = createContext<ImageViewerContextProps | null>(null)

// Hooks
import { useTypedSelector } from 'hooks'

// Helpers
import { getImageURL } from 'helpers'

// Components
import { Icon } from 'components'

// Types
import { IOrder, IOrderProducts, IProductImages2 } from 'types'
interface ImageViewerContextProps {
  showImage: (src: string | File, alt?: string, type?: 'one' | 'many', imagesType?: 'products' | 'warehouse', datas?: string[]) => void
  closeImage: () => void
  imageIsOpened: boolean
}

export function useImageViewer(): ImageViewerContextProps {
  const context = useContext(ImageViewerContext)

  if (!context) {
    console.warn('useImageViewer was accessed outside of ImageViewerProvider.')
    return { showImage: () => {}, closeImage: () => {}, imageIsOpened: false }
  }
  return context
}

export const ImageViewerProvider = ({ children }: { children: ReactNode }) => {
  const {
    cache: {
      orders: { orders },
    },
  } = useTypedSelector((s) => s.store)

  const [imageType, setImageType] = useState<'one' | 'many'>('one')
  const [imagesViewType, setImagesViewType] = useState<'products' | 'warehouse'>('products')

  const [imageSrc, setImageSrc] = useState<string | File | null>(null)
  const [imageAlt, setImageAlt] = useState<string | undefined>(undefined)

  const [images, setImages] = useState<any[]>([])
  const [zoomLevel, setZoomLevel] = useState<number>(1)
  const [photoIndex, setPhotoIndex] = useState<number>(0)

  const showImage = (src: string | File, alt?: string, type: 'one' | 'many' = 'one', imagesType: 'products' | 'warehouse' = 'products', datas: string[] | null = null) => {
    setImageSrc(src)
    setImageAlt(alt)
    setImageType(type)
    setImagesViewType(imagesType)
    if (datas && datas?.length > 0) setImages(datas)
  }

  const closeImage = () => {
    setImageType('one')
    setImagesViewType('products')

    setImages([])
    setImageSrc(null)
    setImageAlt(undefined)

    setZoomLevel(1)
    setPhotoIndex(0)
  }

  useEffect(() => {
    if (typeof imageSrc === 'string' && imageType === 'many') {
      const [orderId, productId, color] = imageSrc?.split('-$-$-')

      const order = orderId !== 'basic-images' ? orders?.find((order: IOrder) => order?.id === orderId) : ''
      if (!(orderId === 'basic-images' ? true : order)) closeImage()

      if (orderId === 'basic-images') {
        // Filtering images with valid colors
        const productImages = images?.map((item: string) => ({ image: typeof item === 'string' ? item : URL?.createObjectURL(item) })) || []
        const currentImageIndex = images?.findIndex((item) => (typeof item === 'string' ? item === color : item?.name === color))

        setImages(productImages)
        setPhotoIndex(currentImageIndex) // For reseting images index
      } else if (imagesViewType === 'products' && order) {
        const product = order?.products?.find((product: IOrderProducts) => product?.id === productId)
        if (!product) closeImage()

        // Getting images colors id
        const productValidImagesColors: string[] = product?.productSavedInfo?.filter((item: IProductImages2) => Number(item?.count) > 0)?.map((item: IProductImages2) => item?.color || '') || []

        // Filtering images with valid colors
        const productImages = product?.product?.images?.filter((item: any) => productValidImagesColors?.includes(item?.color)) || []
        setImages(productImages)

        setPhotoIndex(productImages?.findIndex((item: any) => item?.color === color)) // For reseting images index
      } else if (order) {
        // Filtering images with valid colors
        const productImages = order?.warehouseImages?.map((item: string) => ({ image: item })) || []
        setImages(productImages)
        setPhotoIndex(productImages?.findIndex((item: { image: string }) => item?.image === color)) // For reseting images index
      }
    } else {
      setPhotoIndex(0)
    }

    setZoomLevel(1) // Reset zoom level
  }, [imageSrc])

  const handleDoubleClick = () => {
    setZoomLevel((p) => (p >= 3.5 ? 1 : p + 1.25))
  }

  const handlePrev = () => {
    setPhotoIndex((photoIndex + images?.length - 1) % images?.length)
    setZoomLevel(1) // Reset zoom level when changing images
  }

  const handleNext = () => {
    setPhotoIndex((photoIndex + 1) % images?.length)
    setZoomLevel(1) // Reset zoom level when changing images
  }

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.55)
  }

  const handleZoomOut = () => {
    setZoomLevel(Math?.max(zoomLevel - 0.55, 1)) // Prevent zooming out below the original size
  }

  const handleBackgroundClick = (e: React.MouseEvent) => {
    const target = e?.target as HTMLElement
    const className = target?.className

    if (typeof className === 'string' && className?.includes('image-viewer-overlay')) closeImage()
  }

  const getImageSource = (): string => {
    if (typeof imageSrc === 'string') {
      if (imageType === 'one') {
        return imageSrc?.includes('uploads/') ? getImageURL(imageSrc?.split('-$-$-')[2] || imageSrc) : images[photoIndex]?.image || ''
      } else {
        return imageSrc?.includes('uploads/')
          ? getImageURL(images[photoIndex]?.image || images[photoIndex] || '')
          : images[photoIndex]?.image?.includes('uploads/')
          ? getImageURL(images[photoIndex]?.image)
          : images[photoIndex]?.image || getImageURL(images[photoIndex]?.image) || images[photoIndex]?.image || images[photoIndex] || ''
      }
    } else {
      return URL?.createObjectURL(imageSrc as Blob) || getImageURL(images[photoIndex]?.image || '')
    }
  }

  useEffect(() => {
    function handleClose(event: KeyboardEvent) {
      if (!event?.altKey && event?.key === 'Escape') closeImage()
    }

    window?.addEventListener('keydown', handleClose)

    return () => {
      window?.removeEventListener('keydown', handleClose)
    }
  }, [])

  return (
    <ImageViewerContext.Provider value={{ showImage, closeImage, imageIsOpened: !!imageSrc }}>
      {children}

      {imageSrc && (
        <div className='image-viewer-overlay overflow-auto not-scroll fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75 z-50' onClick={handleBackgroundClick}>
          <div className='relative p-10'>
            <img
              src={getImageSource()}
              alt={imageType === 'one' ? imageAlt || `Image ${photoIndex + 1}` : `Image ${photoIndex + 1}`}
              style={{
                transform: `scale(${zoomLevel})`,
                maxHeight: '80vh',
                maxWidth: '80vw',
                transition: 'transform 0.3s ease-in-out',
              }}
              className='rounded-lg'
              onDoubleClick={handleDoubleClick}
            />
            <div className={`absolute top-1 right-2 p-2 flex space-x-4 rounded-2xl ${zoomLevel > 1 ? 'bg-[#00000038]' : ''}`}>
              <Icon icon='fa:search-plus' onClick={handleZoomIn} className='text-white text-2xl cursor-pointer' />
              <Icon icon='fa:search-minus' onClick={handleZoomOut} className='text-white text-2xl cursor-pointer' />
              <Icon icon='fa:times' onClick={closeImage} className='text-white text-2xl cursor-pointer' />
            </div>
            {imageType === 'many' && images?.length > 1 && (
              <div className={`absolute bottom-1 left-2 p-2 flex space-x-4 rounded-2xl ${zoomLevel > 1 ? 'bg-[#00000038]' : ''}`}>
                <Icon icon='fa:arrow-left' onClick={handlePrev} className='text-white text-2xl cursor-pointer' />
                <Icon icon='fa:arrow-right' onClick={handleNext} className='text-white text-2xl cursor-pointer' />
              </div>
            )}
          </div>
        </div>
      )}
    </ImageViewerContext.Provider>
  )
}