export default {
  add: 'Добавить',
  addItem: 'Добавить элемент',
  update: 'Обновлять',
  save: 'Сохранить',
  saveAll: 'Сохранить все',
  saveChanges: 'Сохранить изменения',
  send: 'Отправить',
  submit: 'Отправить',
  copyAs: 'Копировать',
  action: 'Действия',
  download: 'Скачать',
  more: 'Больше',
  delete: 'Удалить',
  edit: 'Редактировать',
  list: 'Список',
  welcomeBack: 'Добро пожаловать',
  trackYourCompany: 'Отслеживайте продукцию вашей компании и ее рост здесь.',

  close: 'Закрыть',
  cancel: 'Отмена',
  closeMenu: 'Закрыть меню',
  goBack: 'Вернуться назад',
  back: 'Назад',

  checkbox: 'Чекбокс',
  date: 'Дата',

  organization: 'Организация',
  selectOrganization: 'Выберите организацию',

  notFound: ' не найдены!',
  datasNotFound: 'Данные не найдены!',
  dataNotFound: 'Информация не найдена!',

  all: 'Все',
  home: 'Дом',

  yes: 'Да',
  no: 'Нет',

  comingSoon: 'Скоро',
  noAccess: 'Доступ запрещен',

  language: 'Язык',
  changeEmail: 'Изменить адрес Э-Почты',
  changePhone: 'Изменить телефон',
  changePassword: 'Изменить пароль',
  changeGeneralInfo: 'Изменить общую информацию',
  changeBirthdate: 'Изменить дату рождения',

  accesses: 'Доступы',
  noAccessesAdded: 'Доступы не добавлены',
  noRolesFound: 'Роли не найдены',

  title: 'Заголовок',
  comment: 'Комментарий',
  value: 'Ценить',
  location: 'Местоположение',
  landmark: 'Ориентир',

  search: 'Поиск',
  select: 'Выбрать',
  selectAll: 'Выбрать все',

  //input placeholders
  enter: 'Введите',

  from: 'От',
  until: 'До',

  workInfo: 'Информация о работе',
  personalAttendanceHistory: 'Личная история посещений',

  forThisDate: 'Для этой даты',

  boss: 'Босс',
  littleBoss: 'Маленький Босс',
  accessRolesConnector: 'Соединитель ролей доступа',
  accessRolesConnectorFull: 'Соединитель ролей доступа - полная возможность',

  //react-toastify
  success: 'Успешно!',
  error: 'Ошибка!',
  warning: 'Внимание!',
  successfullyAdded: 'Успешно добавлено!',
  successfullyUpdated: 'Успешно обновлено!',
  successfullyDeleted: 'Успешно удалено!',

  processing: 'Обработка',
  exchangeRate: 'Обменный курс',

  currency: 'Валюта',
  payOffice: 'Платежный офис',

  confirmed: 'Подтверждено',
  notConfirmed: 'Не подтверждено',

  Count: 'Количество',
  UntilCarFull: 'Пока машина не заполнится',

  done: 'Выполнено',
  view: 'Просмотр',

  closeDetail: 'Закрыть детали',
  viewDetail: 'Посмотреть подробности',

  unselect: 'Отменить выбор',
  kirim: 'ВХОД',
  chiqim: 'ВЫХОД',

  price: 'Цена',
  amount: 'Сумма',
  remainder: 'Остаток',
  totalPrice: 'Общая цена',
  about: 'О',
  explanation: 'Объяснение',
}