export default {
  add: "Qo'shish",
  addItem: "Element qo'shish",
  update: 'Yangilash',
  save: 'Saqlash',
  saveAll: 'Hammasini saqlash',
  saveChanges: "O'zgarishlarni saqlang",
  send: 'Yuborish',
  submit: 'Yuborish',
  copyAs: 'Nusxalash',
  action: 'Harakat',
  download: 'Yuklab olish',
  more: "Ko'proq",
  delete: "O'chirish",
  edit: 'Tahrirlash',
  list: 'Roʻyxat',
  welcomeBack: 'Xush kelibsiz',
  trackYourCompany: "Kompaniyangiz mahsulotlari va ularning o'sishini shu yerda kuzatib boring.",

  close: 'Yopish',
  cancel: 'Bekor qilish',
  closeMenu: 'Menyuni yopish',
  goBack: 'Ortga qaytish',
  back: 'Orqaga',

  checkbox: 'Belgilash',
  date: 'Sana',

  organization: 'Tashkilot',
  selectOrganization: 'Tashkilotni tanlang',

  notFound: ' topilmadi!',
  datasNotFound: 'Maʼlumotlar topilmadi!',
  dataNotFound: 'Maʼlumot topilmadi!',

  all: 'Hammasi',
  home: 'Uy',

  yes: 'Ha',
  no: "Yo'q",

  comingSoon: 'Tez orada',
  noAccess: 'Ruxsat berilmagan',

  language: 'Til',
  changeEmail: "E-Pochtani o'zgartirish",
  changePhone: "Telefon raqamni o'zgartirish.",
  changePassword: "Parolni o'zgartirish",
  changeGeneralInfo: "Umumiy ma'lumotni o'zgartirish",
  changeBirthdate: "Tug'ilgan sanani o'zgartirish",

  accesses: 'Huquqlar',
  noAccessesAdded: "Huquqlar qo'shilmagan",
  noRolesFound: 'Rollar topilmadi',

  title: 'Sarlavha',
  comment: 'Izoh',
  location: 'Joylashuv',
  landmark: 'Moʻljal',

  search: 'Qidiruv',
  select: 'Tanlash',
  selectAll: 'Hammasini tanlash',

  //input placeholders
  enter: 'Kiriting:',

  from: 'Dan',
  until: 'Gacha',

  workInfo: "Ish haqida ma'lumot",
  personalAttendanceHistory: 'Shaxsiy davomat tarixi',

  forThisDate: 'Ushbu sana uchun',

  boss: 'Rahbar',
  littleBoss: 'Kichik Rahbar',
  accessRolesConnector: "Huquq rollari bog'lovchisi",
  accessRolesConnectorFull: "Huquq rollari bog'lovchisi - to'liq imkoniyat",

  //react-toastify
  success: 'Muvaffaqiyatli!',
  error: 'Xatolik!',
  warning: 'Diqqat!',
  successfullyAdded: 'Muvaffaqiyatli qoʻshildi!',
  successfullyUpdated: 'Muvaffaqiyatli yangilandi!',
  successfullyDeleted: 'Muvaffaqiyatli oʻchirildi!',

  processing: 'Qayta ishlanmoqda',
  exchangeRate: 'Valyuta kursi',

  currency: 'Valyuta',
  payOffice: 'Toʻlov ofisi',

  confirmed: 'Tasdiqlangan',
  notConfirmed: 'Tasdiqlanmagan',

  Count: 'Miqdor',
  UntilCarFull: "Mashina to'lgunicha",

  done: 'Bajarildi',
  view: "Ko'rish",

  closeDetail: 'Tafsilotlarni yopish',
  viewDetail: "Tafsilotlarni ko'rish",
  
  unselect: 'Tanlovni bekor qilish',
  kirim: 'KIRIM',
  chiqim: 'CHIQIM',

  price: 'Narx',
  amount: 'Miqdor',
  remainder: 'Qoldiq',
  totalPrice: 'Umumiy narx',
  about: 'Haqida',
  explanation: 'Tushuntirish',
}