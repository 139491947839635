export default {
  organizations: 'Organizations',
  dashboard: 'Dashboard',
  warehouse: 'Warehouse',
  supply: 'Supply',
  trade: 'Trade',
  cash: 'Cash',
  hr: 'Human resources',
  production: 'Production',
  finance: 'Finance',
  accounting: 'Accounting',
  logistics: 'Logistics',
  edu: 'Edu',
  crm: 'CRM',
  bi: 'Business intelligence',
  services: 'Services with different personalities',
  additions: 'Additions',
  company: 'Company',
  task: 'Task',
  tasks: 'Tasks',
  transactions: 'Transactions',
  payroll: 'Payroll',

  // Trade
  clients: 'Customers',
  saleProductToClient: 'Selling a product to a customer',
  saledProductsToClientHistory: 'History of products sold to the customer',

  // HR
  employee: 'Employee',
  employees: 'Employees',
  departments: 'Departments',
  positions: 'Positions',
  adminRoles: 'Admin Roles',
  attendanceHistory: 'Attendance History',
  employeesContracts: 'Employees Contracts',
  comeOrWentCreator: 'Come or Went Creator for employees',

  // Additions
  locations: 'Locations',
  address: 'Address',
  conclusions: 'Conclusions',
  probabilities: 'Probabilities',
  supplier_types: 'Supplier types',
  topics: 'Topics',
  advertisements: 'Advertisements',
  companyPositions: 'Company positions',
  manufacturer: 'Manufacturer',
  brand: 'Brand',
  group: 'Group',
  uom: 'UOM',
  uom2: 'Unit of measurement',
  type: 'Type',
  serviceGroup: 'Service group',
  service: 'Service',
  pay_office: 'Pay office',
  operational_point: 'Operational point',
  currencies: 'Currencies',
  healthStatus: 'Health status',
  nationality: 'Nationality',
  language: 'Language',
  languagePosition: 'Language Position',
  livingStandards: 'Living standards',
  product_metadata: 'Product metadata',
  category: 'Category',

  // Warehouse
  warehousesList: 'Warehouses list',
  productDatas: 'Product',
  productsRemainders: 'Products Remainders',
  productsList: 'Products list',
  productAdd: 'Add product',
  variantsList: 'Variants list',
  variantAdd: 'Add variant',
  warehouseReport: 'Warehouse Report',
  warehouses: 'Warehouses',
  variants: 'Variants',
  inventoryWarehouseBalance: 'Inventory of warehouse balance',
  changeCostProductsRemaining: 'Change the cost of products remaining',
  settingSellingPriceProducts: 'Setting the selling price of products',

  // Services
  servicesHistory: 'Services history',
  servicesPersons: 'Persons',
  servicesDifferentPersons: 'Different Persons',
  servicesPersonsList: 'Persons list',
  servicesPersonAdd: 'Add new Person',
  servicesHistoriesList: 'History of buying and selling services',
  servicesHistoryAdd: 'Service registration',

  // Payroll
  paymentSalaryToEmployees: 'Payment of salaries to employees',
  payrollHistory: 'Employees salary transactions history',
  recalculateSalaries: 'Recalculate Salaries',

  // Tasks
  myTasks: 'My tasks',
  tasksAssign: 'Assign new task',
  underControl: 'Tasks under control',
  allActiveTasks: 'All active tasks',
  recurringTasks: 'Recurring tasks',
  taskCreate: 'Creating task',
  taskEdit: 'Editing task',
  taskCancellation: 'Cancellation of task',
  taskDeleteFromHistory: 'Delete task from history',
  tasksHistory: 'Tasks history',

  // Recurring Tasks
  recurringTasksList: 'Recurring tasks list',
  createRecurringTask: 'Create recurring task',

  // Cash
  typesOfExpenses: 'Types of expenses',
  changeMainCurrency: 'Change main currency',
  changeCurrenciesExchangeRates: 'Change currencies exchange rates',

  // Supply
  suppliers: 'Suppliers',
  supplyHistory: 'Supply history',
  buyProductFromSupplier: 'Buy product from supplier',
  supplyReport: 'Supply report',

  products: 'Products',
  categories: 'Categories',
  materials: 'Materials',
  colors: 'Colors',
  color: 'Color',
  paymentMethods: 'Payment Methods',
  paymentMethod: 'Payment method',
  news: 'News',
  selfADS: 'Self-ADS',
  users: 'Users',
  subscribers: 'Subscribers',
  accountingBook: 'Accounting book',
  manufacturersWarehouse: 'Manufacturers Warehouse',
  telegramBotSubscribers: 'Telegram Bot Subscribers',
  admins: 'Admins',
  roles: 'Roles',
  telegramSettings: 'Telegram Settings',
  territories: 'Territories',
  clientPositions: 'Client Positions',
  warehouseLocations: 'Warehouse Locations',
  cars: 'Cars',
  season: 'Season',
  status: 'Status',
  productsBarcodeCreator: 'Products Barcode Creator',
  productsMarkerCreator: 'Products Marker Creator',
  productsPDFCreator: 'Products PDF Creator',
  ordersAcceptance: 'Orders Acceptance',
  overOfStock: 'Over of Stock',
}