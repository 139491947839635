export default {
  order: 'Buyurtma',
  orders: 'Buyurtmalar',
  ordersForManufacturers: 'Ishlab chiqaruvchilar uchun buyurtmalar',
  createOrder: 'Yangi buyurtma ochish',
  createOrderForManufacturer: 'Ishlab chiqaruvchi uchun yangi buyurtma ochish',
  dashboard: 'Buyurtmalar va hisoblar paneli',

  myOrders: 'Mening Buyurtmalarim',
  noSellerYet: "Hali sotuvchisi yo'q",
  inProcess: 'Jarayonda',
  notStarted: 'Boshlanmagan',

  urgently: 'Shoshilinch',
  bron: 'Bron',
  bron2: 'Dastlab vaqtincha band qilish holatida turganmi ?',

  youHaveNoOrders: "Sizda buyurtma yo'q!",
  myOrder: 'Bu mening buyurtmam',
  warehouser: 'Omborchi',
  recipient: 'Qabul qiluvchi',
  client: 'Mijoz',
  start: 'Buyurtma jarayonini boshlang',

  // Statuses
  progress1: 'Buyurtma hali sotuvchi tomonidan qabul qilinmagan!',
  progress2: 'Buyurtma sotuvchi tomonidan qabul qilingan va omborchi hali tanlanmagan!',
  progress3: 'Buyurtma uchun omborchi tanlangan, ammo omborchi hali buyurtmani qabul qilmagan!',
  progress4: 'Omborchi buyurtmadagi mahsulotlar sonini tekshirmoqda!',
  progress5: "Omborchi buyurtmadagi mahsulotlarni tekshirishni tugatdi va mijoz buyurtmani o'zi qabul qilib oldi!",
  progress6: 'Buyurtma mijozga yetkazib berish jarayonida!',
  progress7: 'Buyurtma mijozga yetkazildi, ammo yetkazib berish holati mijoz tomonidan hali tasdiqlanmagan!',
  progress8: "Buyurtma mijozga yetkazildi va yetkazib berish holati mijoz tomonidan tasdiqlandi, ammo mahsulotlar soni hali to'g'ri ekanligi tasdiqlanmagan.",
  progress9: "Buyurtma mijozga yetkazildi va mijoz yetkazib berish va mahsulot soni to'g'ri ekanligini tasdiqladi!",
  progress10: 'Hali boshlanmadi!',
}