export default {
  addWarehouse: 'Add Warehouse',

  //product table
  productTitle: 'Product Title',
  price: 'Price',
  category: 'Category',
  group: 'Group',
  image: 'Image',
  product: 'Product',
  warehouse: 'Warehouse',
  count: 'Count',
  barcode: 'Barcode',
  uom: 'Unity Of Measure',
  brand: 'Brand',
  type: 'type',
  additionFields: 'Addition Fields',

  available: 'Available',

  selectProduct: 'Select Product',
  minSaleCount: 'Min Sale Count',
  maxSaleCount: 'Max Sale Count',
  salePrice: 'Sale Price',
  purchasePrice: 'Purchase Price',
  quantityInPackage: 'Quantity In Package',
  minPackageCountBeforeUnitSale: 'Min Package Count Before Unit Sale',

  //warehouse report
  reload: 'Reload',
  goToSupplyHistory: 'Go to supply history',
  goToSaleHistory: 'Go to sale history',
  incomeCount: 'Income Count',
  outlayCount: 'Outlay Count',
  incomeMoney: 'Income Money',
  outlayMoney: 'Outlay Money',
  activeWarehouses: 'Active Warehouses',
  detailedInfoToSelectedWarehouse: 'Detailed info to ',
  detailedInfoToSelectedProduct: 'Detailed info to ',

  actionHappenDate: 'Action Happen Date',
  indicator: 'Indicator',
  document: 'Document',

  characteristics: 'Characteristics',
}