export default {
  add: 'Add',
  addItem: 'add new',
  update: 'Update',
  submit: 'Submit',
  save: 'Save',
  saveAll: 'Save all',
  saveChanges: 'Save changes',
  send: 'Send',
  copyAs: 'Copy as',
  action: 'Actions',
  download: 'Download',
  more: 'More',
  delete: 'Delete',
  remove: 'Remove',
  edit: 'Edit',
  list: 'List',
  welcomeBack: 'Welcome back',
  trackYourCompany: "Track your company's products and their growth here.",

  close: 'Close',
  cancel: 'Cancel',
  closeMenu: 'Close Menu',
  goBack: 'Go Back',
  back: 'Back',

  checkbox: 'Checkbox',
  date: 'Date',

  organization: 'Organization',
  selectOrganization: 'Select Organization',

  notFound: ' not found!',
  datasNotFound: 'Datas not found!',
  dataNotFound: 'Information not found!',

  all: 'All',
  home: 'Home',

  yes: 'Yes',
  no: 'No',

  comingSoon: 'Coming soon',
  noAccess: 'Access denied',

  language: 'Language',
  changeEmail: 'Change Email',
  changePhone: 'Change Phone',
  changePassword: 'Change Password',
  changeGeneralInfo: 'Change General Information',
  changeBirthdate: 'Change Birthdate',

  accesses: 'Accesses',
  noAccessesAdded: 'No accesses added',
  noRolesFound: 'No roles found',

  title: 'Title',
  comment: 'Comment',
  value: 'Value',
  location: 'Location',
  landmark: 'Landmark',
  description: 'Description',

  search: 'Search',
  select: 'Select',
  selectAll: 'Select All',

  //input placeholders
  enter: 'Enter',

  from: 'From',
  until: 'Until',

  workInfo: 'Work info',
  personalAttendanceHistory: 'Personal Attendance History',

  forThisDate: 'For this date',

  boss: 'Boss',
  littleBoss: 'Little Boss',
  accessRolesConnector: 'Access roles connector',
  accessRolesConnectorFull: 'Access roles connector - full opportunity',

  //react-toastify
  success: 'Success!',
  error: 'Error!',
  warning: 'Warning!',
  successfullyAdded: 'Successfully added',
  successfullyUpdated: 'Successfully updated',
  successfullyDeleted: 'Successfully deleted',

  processing: 'Processing',
  exchangeRate: 'Exchange rate',

  currency: 'Currency',
  payOffice: 'Pay office',

  confirmed: 'Confirmed',
  notConfirmed: 'Not confirmed',

  Count: 'Count',
  UntilCarFull: 'Until Car Full',

  done: 'Done',
  view: 'View',

  closeDetail: 'Close details',
  viewDetail: 'View details',

  unselect: 'Unselect',
  kirim: 'INPUT',
  chiqim: 'OUTPUT',

  price: 'Price',
  amount: 'Amount',
  remainder: 'Remainder',
  totalPrice: 'Total Price',
  about: 'About',
  explanation: 'Explanation',
}