export default {
  organizations: 'Tashkilotlar',
  dashboard: 'Boshqaruv paneli',
  warehouse: 'Ombor',
  supply: 'Taminot',
  trade: 'Savdo',
  cash: 'Kassa',
  hr: 'Inson resurslari',
  hr2: "Kadrlar bo'limi",
  production: 'ishlab chiqarish',
  finance: 'Moliya',
  accounting: 'Buxgalteriya hisobi',
  logistics: 'Logistika',
  edu: 'Edu',
  crm: 'CRM',
  bi: 'Biznes tahlili',
  services: 'Turli xil shaxslar bilan xizmatlar',
  additions: "Qo'shimchalar",
  company: 'Kompaniya',
  task: 'Vazifa',
  tasks: 'Vazifalar',
  // transactions: 'Bitimlar',
  transactions: 'Tranzaksiyalar',
  payroll: 'Ish haqi',

  // Trade
  clients: 'Mijozlar',
  saleProductToClient: 'Mijozga mahsulot sotish',
  saledProductsToClientHistory: 'Mijozga sotilgan mahsulotlar tarixi',

  // HR
  employee: 'Xodim',
  employees: 'Xodimlar',
  departments: "Bo'limlar",
  positions: 'Lavozimlar',
  adminRoles: 'Administrator rollari',
  attendanceHistory: 'Davomat tarixi',
  employeesContracts: 'Xodimlarning shartnomalari',
  comeOrWentCreator: "Xodimlar uchun Keldim yoki Ketdim qo'shuvchi",

  // Additions
  locations: 'Lokatsiyalar',
  address: 'Manzillar',
  conclusions: 'Xulosalar',
  probabilities: 'Ehtimollar',
  supplier_types: "Ta'minotchi turlari",
  topics: 'Mavzular',
  advertisements: 'Reklamalar',
  companyPositions: 'Kompaniya lavozimlari',
  manufacturer: 'Ishlab chiqaruvchi',
  brand: 'Brend',
  group: 'Guruh',
  uom: 'UOM',
  uom2: "O'lchov birligi",
  type: 'Tur',
  serviceGroup: 'Xizmat guruhi',
  service: 'Xizmat',
  pay_office: "To'lov idorasi",
  operational_point: 'Operatsion nuqta',
  currencies: 'Valyutalar',
  healthStatus: 'Salomatlik holati',
  nationality: 'Millati',
  language: 'Til',
  languagePosition: 'Til darajasi',
  livingStandards: 'Turmush darajasi',
  product_metadata: "Mahsulot metama'lumotlari",
  category: 'Kategoriya',

  // Warehouse
  warehousesList: "Omborlar ro'yxati",
  productDatas: 'Mahsulot',
  productsRemainders: "Mahsulotlar Qoldig'i",
  productsList: "Mahsulotlar ro'yxati",
  productAdd: "Mahsulot qo'shish",
  variantsList: "Variantlar ro'yxati",
  variantAdd: "Variant qo'shish",
  warehouseReport: 'Ombor hisoboti',
  warehouses: 'Omborlar',
  variants: 'Variantlar',
  inventoryWarehouseBalance: "Ombor qoldig'ini inventarizatsiya qilish",
  changeCostProductsRemaining: "Mahsulotlar qoldig'ini tannarxini o'zgartirish",
  settingSellingPriceProducts: 'Mahsulotlarni sotilish narxini belgilash',

  // Services
  servicesHistory: 'Xizmatlar tarixi',
  servicesPersons: 'Shaxslar',
  servicesDifferentPersons: 'Turli xil Shaxslar',
  servicesPersonsList: "Shaxslar ro'yxati",
  servicesPersonAdd: "Yangi Shaxs qo'shish",
  servicesHistoriesList: 'Xizmatlarni oldi-sotti tarixi',
  servicesHistoryAdd: 'Service qayd etish',

  // Payroll
  paymentSalaryToEmployees: "Xodimlarga ish haqini to'lash",
  payrollHistory: 'Xodimlarning ish haqi operatsiyalari tarixi',
  recalculateSalaries: 'Ish haqini qayta hisoblash',

  // Tasks
  myTasks: 'Mening vazifalarim',
  tasksAssign: 'Yangi vazifa tayinlash',
  underControl: 'Nazorat ostidagi vazifalar',
  allActiveTasks: 'Barcha faol vazifalar',
  recurringTasks: 'Takroriy vazifalar',
  taskCreate: "Vazifa qo'shish",
  taskEdit: 'Vazifani tahrirlash',
  taskCancellation: 'Vazifani bekor qilish',
  taskDeleteFromHistory: "Tarixdan vazifani o'chirish",
  tasksHistory: 'Vazifalar tarixi',

  // Recurring Tasks
  recurringTasksList: 'Recurring tasks list',
  createRecurringTask: "Takroriy vazifa qo'shish",

  // Cash
  typesOfExpenses: 'Xarajatlar turlari',
  changeMainCurrency: "Asosiy valyutani o'zgartirish",
  changeCurrenciesExchangeRates: "Valyuta kurslarini o'zgartirish",

  // Supply
  suppliers: 'Taminotchi',
  supplyHistory: 'Taminot tarixi',
  buyProductFromSupplier: 'Taminotchidan mahsulot sotib olish',
  supplyReport: 'Taminot hisoboti',


  products: 'Mahsulotlar',
  categories: 'Kategoriyalar',
  materials: 'Materiallar',
  colors: 'Ranglar',
  color: 'Rang',
  paymentMethods: "To'lov turlari",
  paymentMethod: "To'lov turi",
  news: 'Yangiliklar',
  selfADS: "Shaxsiy e'lonlar",
  users: 'Foydalanuvchilar',
  subscribers: 'Obunachilar',
  accountingBook: 'Buxgalteriya kitobi',
  manufacturersWarehouse: 'Ishlab chiqaruvchilar Ombori',
  telegramBotSubscribers: 'Telegram Bot Obunachilari',
  admins: 'Adminlar',
  roles: 'Rollar',
  telegramSettings: 'Telegram Sozlamalari',
  territories: 'Hududlar',
  clientPositions: 'Mijoz pozitsiyalari',
  warehouseLocations: 'Ombor lokatsiyalari',
  cars: 'Avtomobillar',
  season: 'Mavsum',
  status: 'Status',
  productsBarcodeCreator: 'Mahsulotlar uchun shtrix kod chiqarish',
  productsMarkerCreator: 'Mahsulotlar uchun markerovka chiqarish',
  productsPDFCreator: 'Mahsulotlar PDF Creator',
  ordersAcceptance: 'Buyurtmalarni qabul qilish',
  overOfStock: 'Zaxiradagi ortiqcha qoldiqlar',
}