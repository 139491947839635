import lang from './lang'
import global from './global'
import navbar from './navbar'
import pagination from './pagination'
import deleteAlert from './deleteAlert'
import departments from './departments'
import warehouse from './warehouse'
import supply from './supply'
import orders from './orders'

const TranslationRU = { lang, global, navbar, pagination, deleteAlert, departments, warehouse, supply, orders }

export default TranslationRU