import { Suspense, useEffect, useMemo, useState } from 'react'
import { useRoutes } from 'react-router'

// Redux store
import { useDispatch } from 'react-redux'
import { changeLoading } from 'store/store'

// Hooks
import { useTypedSelector } from 'hooks'
import { useTranslation } from 'react-i18next'

// Helpers
import generateRoutes from './routesLinks'
import { checkTokenValidity, handleOnline, switchTheme } from 'helpers'

// Components
import { Loading, ScrollToTop } from 'components'

// Locale languages
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Importing Locale translated texts
import TranslationEN from 'Locale/En'
import TranslationRU from 'Locale/Ru'
import TranslationUZ from 'Locale/Uz'

// i18n initialization
i18n?.use(initReactI18next)?.init({
  resources: {
    en: { translation: TranslationEN },
    ru: { translation: TranslationRU },
    uz: { translation: TranslationUZ },
  },
  lng: localStorage?.getItem('$admin$.$flakon$.$uz$language$') || 'ru',
  fallbackLng: localStorage?.getItem('$admin$.$flakon$.$uz$language$') || 'ru',
  interpolation: { escapeValue: false },
})

export default function Router(): React.JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const params: URLSearchParams = useMemo(() => new URLSearchParams(window?.location?.search), [])

  const {
    isLoading,
    user: { isAuth, rolesId },
  } = useTypedSelector((s) => s.store)

  const [isOnline, setIsOnline] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)

  // Memoized routes generation
  const routes = useRoutes(useMemo(() => generateRoutes(isAuth, rolesId || []), [isAuth, rolesId]))

  useEffect(() => {
    const validateUser = async () => {
      const tokenExists = localStorage.getItem('$W$E$B$F$L$A$K$O$N$.$U$Z$T$O$K$E$N$')
      if (!tokenExists && !params?.get('telegram_bot')) {
        dispatch(changeLoading(false))
        setLoading(false)
        return
      }

      const isValid = await checkTokenValidity(dispatch)
      if (!isValid) {
        setLoading(false)
        return
      }

      const isFromTelegramBot: boolean = typeof window !== 'undefined' && typeof (window as any)?.TelegramWebviewProxy !== 'undefined'
      if (!isFromTelegramBot) {
        params?.delete('telegram_bot')
        window?.history?.pushState({}, '', `?${params?.toString()}`)
      }

      setLoading(false)
    }

    if (isOnline) validateUser()
  }, [isOnline])

  useEffect(() => {
    switchTheme('get')
  }, [])

  useEffect(() => {
    handleOnline(true, isOnline, setIsOnline)
    const handleConnectionChange = () => handleOnline(false, isOnline, setIsOnline)
    window?.addEventListener('online', handleConnectionChange)
    window?.addEventListener('offline', handleConnectionChange)

    return () => {
      window?.removeEventListener('online', handleConnectionChange)
      window?.removeEventListener('offline', handleConnectionChange)
    }
  }, [isOnline])

  return (
    <Suspense fallback={<Loading />}>
      {!loading && routes}
      {isLoading && <Loading bg={!isAuth} />}
      <ScrollToTop />
    </Suspense>
  )
}