export default {
  addWarehouse: 'Добавить склад',

  //product table
  productTitle: 'Название продукта',
  price: 'Цена',
  category: 'Категория',
  group: 'Группа',
  image: 'Изображение',
  product: 'Продукт',
  warehouse: 'Склад',
  count: 'Количество',
  barcode: 'Штрихкод',
  uom: 'Единица измерения',
  brand: 'Бренд',
  type: 'Тип',
  additionFields: 'Дополнительные поля',

  selectProduct: 'Выбрать продукт',
  minSaleCount: 'Минимальное количество для продажи',
  maxSaleCount: 'Максимальное количество для продажи',
  salePrice: 'Цена продажи',
  purchasePrice: 'Цена покупки',
  quantityInPackage: 'Количество в упаковке',
  minPackageCountBeforeUnitSale: 'Минимальное количество упаковок перед продажей поштучно',

  //warehouse report
  reload: 'Перезагрузить',
  goToSupplyHistory: 'Перейти к истории поставок',
  goToSaleHistory: 'Перейти к истории продаж',
  incomeCount: 'Количество поступлений',
  outlayCount: 'Количество расходов',
  incomeMoney: 'Доход',
  outlayMoney: 'Расход',
  activeWarehouses: 'Активные склады',
  detailedInfoToSelectedWarehouse: 'Подробная информация о выбранном складе',
  detailedInfoToSelectedProduct: 'Подробная информация о выбранном продукте',

  actionHappenDate: 'Дата события',
  indicator: 'Индикатор',
  document: 'Документ',

  characteristics: 'Характеристики',
}