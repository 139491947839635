import { redirect } from 'react-router'
import { createSlice } from '@reduxjs/toolkit'
import { initialPagination } from 'config'

// Types
import {
  IADSInfo,
  IAdditionalData,
  IAdditionalDataNames,
  IAdmin,
  IAdminRoles,
  ICategoryData,
  IClients,
  IClientsDB,
  IColor,
  ICompanyNewsData,
  IMaterial,
  IOrder,
  IOrderAcceptance,
  IOrderForManufacturer,
  IPagination,
  IPaymentMethod,
  IProduct,
  IProductData,
  IProductInWarehouse,
  ISettings,
  IStatistics,
  ITelegramBotUser,
  ITelegramSettings,
  IUser,
  ThemeType,
} from 'types'

interface IStoreCache {
  categories: {
    categories: ICategoryData[] | null
    pagination: IPagination
  }
  products: {
    products: IProductData[] | null
    pagination: IPagination
  }
  materials: {
    materials: IMaterial[] | null
    pagination: IPagination
  }
  colors: {
    colors: IColor[] | null
    pagination: IPagination
  }
  companyNews: {
    companyNews: ICompanyNewsData[] | null
    pagination: IPagination
  }
  selfADS: {
    selfADS: IADSInfo[] | null
    pagination: IPagination
  }
  telegramBotUsers: {
    telegramBotUsers: ITelegramBotUser[] | null
    pagination: IPagination
  }
  users: {
    users: IUser[] | null
    pagination: IPagination
  }
  admins: {
    admins: IAdmin[] | null
    pagination: IPagination
  }
  roles: {
    roles: IAdminRoles[] | null
    pagination: IPagination
  }
  orders: {
    orders: IOrder[] | null
    pagination: IPagination
  }
  ordersForManufacturers: {
    ordersForManufacturers: IOrderForManufacturer[] | null
    pagination: IPagination
  }
  accounting: {
    accounting: IAdminRoles[] | null
    pagination: IPagination
  }
  accountingForManufacturers: {
    accountingForManufacturers: IAdminRoles[] | null
    pagination: IPagination
  }
  paymentMethods: {
    paymentMethods: IPaymentMethod[] | null
    pagination: IPagination
  }
  userWarehouseProducts: {
    userWarehouseProducts: IProductInWarehouse[] | null
    pagination: IPagination
  }
  ordersAcceptance: {
    ordersAcceptance: IOrderAcceptance[] | null
    pagination: IPagination
  }
  clientsDB: {
    clientsDB: IClientsDB[] | null
    pagination: IPagination
  }
  territories: {
    territories: IAdditionalData[] | null
    pagination: IPagination
  }
  brands: {
    brands: IAdditionalData[] | null
    pagination: IPagination
  }
  clientPositions: {
    clientPositions: IAdditionalData[] | null
    pagination: IPagination
  }
  warehouseLocations: {
    warehouseLocations: IAdditionalData[] | null
    pagination: IPagination
  }
  cars: {
    cars: IAdditionalData[] | null
    pagination: IPagination
  }
  season: {
    season: IAdditionalData[] | null
    pagination: IPagination
  }
  status: {
    status: IAdditionalData[] | null
    pagination: IPagination
  }
  clients: IClients | []
  statistics: IStatistics | null
}

interface IInitialState {
  isLoading: boolean
  theme: ThemeType
  isFullHeight: boolean
  cache: IStoreCache
  user: IAdmin
  settings: ISettings | null
  telegramSettings: ITelegramSettings | null
  sessionIsExpired: boolean
}

const initialState: IInitialState = {
  isLoading: true,
  theme: (localStorage?.getItem('$flakonuzadmindashboard$theme$') as ThemeType) || 'light',
  isFullHeight: false,
  cache: {
    categories: {
      categories: null,
      pagination: initialPagination,
    },
    products: {
      products: null,
      pagination: initialPagination,
    },
    materials: {
      materials: null,
      pagination: initialPagination,
    },
    colors: {
      colors: null,
      pagination: initialPagination,
    },
    companyNews: {
      companyNews: null,
      pagination: initialPagination,
    },
    selfADS: {
      selfADS: null,
      pagination: initialPagination,
    },
    telegramBotUsers: {
      telegramBotUsers: null,
      pagination: initialPagination,
    },
    users: {
      users: null,
      pagination: initialPagination,
    },
    admins: {
      admins: null,
      pagination: initialPagination,
    },
    roles: {
      roles: null,
      pagination: initialPagination,
    },
    orders: {
      orders: null,
      pagination: initialPagination,
    },
    ordersForManufacturers: {
      ordersForManufacturers: null,
      pagination: initialPagination,
    },
    accounting: {
      accounting: null,
      pagination: initialPagination,
    },
    accountingForManufacturers: {
      accountingForManufacturers: null,
      pagination: initialPagination,
    },
    paymentMethods: {
      paymentMethods: null,
      pagination: initialPagination,
    },
    userWarehouseProducts: {
      userWarehouseProducts: null,
      pagination: initialPagination,
    },
    ordersAcceptance: {
      ordersAcceptance: null,
      pagination: initialPagination,
    },
    clientsDB: {
      clientsDB: null,
      pagination: initialPagination,
    },
    territories: {
      territories: null,
      pagination: initialPagination,
    },
    brands: {
      brands: null,
      pagination: initialPagination,
    },
    clientPositions: {
      clientPositions: null,
      pagination: initialPagination,
    },
    warehouseLocations: {
      warehouseLocations: null,
      pagination: initialPagination,
    },
    cars: {
      cars: null,
      pagination: initialPagination,
    },
    season: {
      season: null,
      pagination: initialPagination,
    },
    status: {
      status: null,
      pagination: initialPagination,
    },
    clients: [],
    statistics: null,
  },
  user: {
    id: '',
    name: '',
    email: '',
    image: '',
    surname: '',
    username: '',
    isAuth: false,
    userId: '',
    userToken: localStorage?.getItem('$W$E$B$F$L$A$K$O$N$.$U$Z$T$O$K$E$N$') || '',
  },
  settings: null,
  telegramSettings: null,
  sessionIsExpired: false,
}

const store = createSlice({
  name: 'store',
  initialState,
  reducers: {
    changeLoading: (state, action) => {
      state.isLoading = action?.payload
    },
    changeTheme: (state, action: { payload: ThemeType }) => {
      state.theme = action?.payload
    },
    changeFullHeight: (state, action) => {
      state.isFullHeight = action?.payload
    },
    changeCategories: (state, action) => {
      state.cache.categories = action?.payload
    },
    changeProducts: (state, action) => {
      state.cache.products = action?.payload?.products
        ? action?.payload
        : { pagination: action?.payload?.pagination || { limit: action?.payload?.length, page: 1, total: action?.payload?.length, totalPages: 1 }, products: action?.payload }
    },
    changeProductInfoIsHide: (state, action) => {
      const { id, isHide } = action?.payload || {}
      state.cache.products = {
        pagination: state.cache.products.pagination || { limit: action?.payload?.length, page: 1, total: action?.payload?.length, totalPages: 1 },
        products: state.cache.products?.products && state.cache.products?.products?.map((product: IProduct) => (id === product?.id ? { ...product, isHide } : product)),
      }
    },
    changeOrders: (state, action) => {
      switch (!!action?.payload?.isUpdateble) {
        case true:
          state.cache.orders = {
            pagination: action?.payload?.pagination || { limit: action?.payload?.length, page: 1, total: action?.payload?.length, totalPages: 1 },
            orders: [...(state.cache.orders.orders || []), ...action.payload.orders],
          }
          break
        case false:
          state.cache.orders = action?.payload?.orders
            ? action?.payload
            : { pagination: action?.payload?.pagination || { limit: action?.payload?.length, page: 1, total: action?.payload?.length, totalPages: 1 }, orders: action?.payload }
          break
      }
    },
    changeOrdersForManufacturers: (state, action) => {
      switch (!!action?.payload?.isUpdateble) {
        case true:
          state.cache.ordersForManufacturers = {
            pagination: action?.payload?.pagination || { limit: action?.payload?.length, page: 1, total: action?.payload?.length, totalPages: 1 },
            ordersForManufacturers: [...(state.cache.ordersForManufacturers.ordersForManufacturers || []), ...action.payload.ordersForManufacturers],
          }
          break
        case false:
          state.cache.ordersForManufacturers = action?.payload?.ordersForManufacturers
            ? action?.payload
            : { pagination: action?.payload?.pagination || { limit: action?.payload?.length, page: 1, total: action?.payload?.length, totalPages: 1 }, ordersForManufacturers: action?.payload }
          break
      }
    },
    changeOrderSalerAccept: (state, action) => {
      const { id, saler, salerInfo } = action?.payload || {}
      state.cache.orders = {
        pagination: state.cache.orders.pagination,
        orders: state.cache.orders?.orders && state.cache.orders?.orders?.map((order: IOrder) => (id === order?.id ? { ...order, whichSaler: saler, salerInfo: salerInfo } : order)),
      }
    },
    changeOrdersAcceptance: (state, action) => {
      state.cache.ordersAcceptance = action?.payload?.ordersAcceptance
        ? action?.payload
        : { pagination: action?.payload?.pagination || { limit: action?.payload?.length, page: 1, total: action?.payload?.length, totalPages: 1 }, ordersAcceptance: action?.payload }
    },
    changeAccounting: (state, action) => {
      state.cache.accounting = action?.payload?.accounting
        ? action?.payload
        : { pagination: action?.payload?.pagination || { limit: action?.payload?.length, page: 1, total: action?.payload?.length, totalPages: 1 }, accounting: action?.payload }
    },
    changeAccountingForManufacturers: (state, action) => {
      state.cache.accountingForManufacturers = action?.payload?.accountingForManufacturers
        ? action?.payload
        : { pagination: action?.payload?.pagination || { limit: action?.payload?.length, page: 1, total: action?.payload?.length, totalPages: 1 }, accountingForManufacturers: action?.payload }
    },
    changeMaterials: (state, action) => {
      state.cache.materials = action?.payload
    },
    changeColors: (state, action) => {
      state.cache.colors = action?.payload
    },
    changeCompanyNews: (state, action) => {
      state.cache.companyNews = {
        companyNews: action?.payload?.news,
        pagination: action?.payload?.pagination || { limit: action?.payload?.length, page: 1, total: action?.payload?.length, totalPages: 1 },
      }
    },
    changeSelfADSInfo: (state, action) => {
      state.cache.selfADS = action?.payload
    },
    changeSelfADSInfoIsHide: (state, action) => {
      const { id, isHide } = action?.payload || {}
      state.cache.selfADS = {
        pagination: state.cache.selfADS.pagination || { limit: action?.payload?.length, page: 1, total: action?.payload?.length, totalPages: 1 },
        selfADS: state.cache.selfADS?.selfADS && state.cache.selfADS?.selfADS?.map((currSelfADS: IADSInfo) => (id === currSelfADS?.id ? { ...currSelfADS, isHide } : currSelfADS)),
      }
    },
    changeTelegramBotUsers: (state, action) => {
      state.cache.telegramBotUsers = action?.payload
    },
    changeUsers: (state, action) => {
      state.cache.users = action?.payload
    },
    changeClientsDB: (state, action) => {
      state.cache.clientsDB = action?.payload
    },
    changeAdmins: (state, action) => {
      state.cache.admins = action?.payload
    },
    changeAdminRoles: (state, action) => {
      state.cache.roles = { roles: action?.payload?.adminRoles, pagination: action?.payload?.pagination || { limit: action?.payload?.length, page: 1, total: action?.payload?.length, totalPages: 1 } }
    },
    changeClients: (state, action) => {
      state.cache.clients = action?.payload
    },
    changeStatistics: (state, action) => {
      state.cache.statistics = action?.payload
    },
    changeUserProfile: (state, action) => {
      state.user = {
        ...state.user,
        name: action?.payload?.name,
        surname: action?.payload?.surname,
        username: action?.payload?.username,
        phone: action?.payload?.phone,
        carNumber: action?.payload?.carNumber,
        phoneCode: action?.payload?.phoneCode,
      }
    },
    changeUserProfileImage: (state, action) => {
      state.user = {
        ...state.user,
        image: action?.payload?.image,
      }
    },
    changeSettings: (state, action) => {
      state.settings = { ...state.settings, ...action?.payload }
      delete (state.settings as any).clients as any
      state.cache.clients = action?.payload?.clients || []
    },
    changeCompanyClientLogos: (state, action) => {
      state.cache.clients = action?.payload || []
    },
    changePaymentMethod: (state, action) => {
      state.cache.paymentMethods = action?.payload
    },
    changeUserWarehouseProducts: (state, action) => {
      state.cache.userWarehouseProducts = action?.payload?.productsInWarehouse
        ? {
            userWarehouseProducts: action?.payload?.productsInWarehouse,
            pagination: action?.payload?.pagination || { limit: action?.payload?.length, page: 1, total: action?.payload?.length, totalPages: 1 },
          }
        : { pagination: action?.payload?.pagination || { limit: action?.payload?.length, page: 1, total: action?.payload?.length, totalPages: 1 }, userWarehouseProducts: action?.payload }
    },
    userAuth: (state, action) => {
      switch (action?.payload?.type) {
        case 'LOGIN':
          state.user = {
            isAuth: true,
            name: action?.payload?.data?.user?.name || action?.payload?.user?.name,
            email: action?.payload?.data?.user?.email || action?.payload?.user?.email,
            image: action?.payload?.data?.user?.image || action?.payload?.user?.image || '',
            surname: action?.payload?.data?.user?.surname || action?.payload?.user?.surname,
            username: action?.payload?.data?.user?.username || action?.payload?.user?.username,
            phone: action?.payload?.data?.user?.phone || action?.payload?.user?.phone || '',
            phoneCode: action?.payload?.data?.user?.phoneCode || action?.payload?.user?.phoneCode || '',
            carNumber: action?.payload?.data?.user?.carNumber || action?.payload?.user?.carNumber || '',
            roles: action?.payload?.data?.user?.roles || action?.payload?.user?.roles,
            rolesId: action?.payload?.data?.user?.rolesId || action?.payload?.user?.rolesId,
            id: action?.payload?.data?.user?.id || action?.payload?.user?.id,
            userId: action?.payload?.data?.user?.id || action?.payload?.user?.id,
            userToken: action?.payload?.data?.access_token || action?.payload?.access_token,
          }
          localStorage?.setItem('$W$E$B$F$L$A$K$O$N$.$U$Z$T$O$K$E$N$', action?.payload?.data?.access_token || action?.payload?.access_token)
          redirect('/dashboard')
          break

        case 'LOGOUT':
          state.user = {
            isAuth: false,
            id: '',
            name: '',
            email: '',
            image: '',
            surname: '',
            username: '',
            phone: '',
            phoneCode: '',
            carNumber: '',
            userId: '',
            userToken: '',
            roles: [],
            rolesId: [],
          }
          localStorage?.removeItem('$W$E$B$F$L$A$K$O$N$.$U$Z$T$O$K$E$N$')
          localStorage?.removeItem('$flakonuzadmindashboard$theme$')

          return initialState
      }
    },
    expireSession: (state) => {
      state.isLoading = true
      state.sessionIsExpired = true
    },
    reset: (_state) => {
      localStorage?.removeItem('$W$E$B$F$L$A$K$O$N$.$U$Z$T$O$K$E$N$')
      localStorage?.removeItem('$flakonuzadmindashboard$theme$')
      return { ...initialState, isLoading: false }
    },
    dynamicChangeAdditionalData: (state, action: { payload: { data: Record<IAdditionalDataNames, IAdditionalData[] | null> & { pagination: IPagination }; path: IAdditionalDataNames } }) => {
      state.cache[action.payload.path] = {
        [action.payload.path]: action.payload.data[action.payload.path] || (action.payload.data as any)?.data,
        pagination: action.payload.data.pagination || { limit: (action?.payload as any)?.length, page: 1, total: (action?.payload as any)?.length, totalPages: 1 },
      } as any
    },
    changeTelegramSettings: (state, action) => {
      state.telegramSettings = { ...state.telegramSettings, ...action?.payload }
    },
  },
})

export const {
  reset,
  userAuth,
  changeTheme,
  changeUsers,
  changeColors,
  changeAdmins,
  changeOrders,
  expireSession,
  changeClients,
  changeLoading,
  changeSettings,
  changeProducts,
  changeClientsDB,
  changeMaterials,
  changeAdminRoles,
  changeCategories,
  changeStatistics,
  changeFullHeight,
  changeAccounting,
  changeSelfADSInfo,
  changeUserProfile,
  changeCompanyNews,
  changePaymentMethod,
  changeOrdersAcceptance,
  changeTelegramBotUsers,
  changeUserProfileImage,
  changeOrderSalerAccept,
  changeProductInfoIsHide,
  changeSelfADSInfoIsHide,
  changeCompanyClientLogos,
  dynamicChangeAdditionalData,
  changeUserWarehouseProducts,
  changeOrdersForManufacturers,
  changeAccountingForManufacturers,
  changeTelegramSettings,
} = store?.actions
export default store.reducer